@import "../colors.scss";
@import "../mixins.scss";

.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 500;
  min-height: 52px;
  padding: 0 2rem;
  background: white;
  border-bottom: 1px solid #e2e5eb;
  box-shadow: 0 0 6px rgba(110, 119, 130, 0.14);
  .wrap-1400 {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .logo-block {
    margin-right: 2rem;
    a {
      font-family: MTSSansBold;
      font-size: 24px;
      line-height: 28px;
      color: #000000;
    }
    .logo {
      width: 122px;
      height: 32px;
      background-image: url("#{$VUE_APP_BASE}/assets/icons/logo.svg");
      background-position: center;
      background-size: cover;
    }
  }

  .mobile-navigation {
    display: none;
    margin-top: 5px;
    margin-right: 0.75rem;
  }

  .navigation {
    a {
      @include medium-text-small-uncolored;
      display: inline-block;
      padding: 1rem 0 0.75rem 0;
      margin: 0 0.75rem;
      line-height: 20px;
      font-weight: 500;
      color: $dark-text;
      text-align: center;
      border-bottom: 2px solid transparent;
      transition: 0.15s;
      cursor: pointer;
      &:hover {
        color: $dark-text;
        text-decoration: none;
        transform: scale(1.04);
      }
    }
  }

  .menu-toggle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
    -webkit-user-select: none;
    user-select: none;

    &:hover {
      span {
        width: 24px;
      }
    }

    input {
      display: flex;
      width: 24px;
      height: 24px;
      position: absolute;
      cursor: pointer;
      opacity: 0;
      z-index: 2;

      &:checked {
        background: #36383F;

        & ~ ul {
          transform: none;
        }

        & ~ span {
          left: 5px;
          &:nth-child(2) {
            transform: rotate(-45deg) translate(-9px, 0px);
          }

          &:nth-child(3) {
            opacity: 0;
          }

          &:nth-child(4) {
            transform: rotate(45deg) translate(-10px, -1px);
          }
        }
      }
    }

    span {
      display: flex;
      width: 22px;
      height: 2px;
      margin-bottom: 5px;
      position: relative;
      background: $dark-text;
      border-radius: 10px;
      z-index: 1;
      transition: 0.3s;

      &:nth-child(3) {
        width: 24px;
      }
    }

    .mobile-menu {
      position: absolute;
      top: 33px;
      left: -16px;
      background-color: $white-text;
      min-width: 170px;
      transition: 0.3s;
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;
      transform: translate(-200px, 0);
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        margin: 0;
        padding: 0;
        transition-delay: 2s;
        a {
          @include medium-text-small-uncolored;
          padding: 0.75rem 1.5rem;
          display: block;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          &:hover {
            color: $dark-text;
            text-decoration: none;
            transform: scale(1.04);
          }
        }
      }
    }
  }

  .right-col {
    .btn {
      font-family: MTSCompactMedium;
      color: white;
      width: 100%;
      max-width: 100px;
      background: $button-red;
      transition: 0.3s;
      border-radius: 6px;
      padding: 0.25rem 1.25rem;
      &:hover {
        transform: scale(1.04);
      }
    }
  }

  @media screen and (max-width: 1360px) {
    .logo-block {
      margin-right: 1.25rem;
    }
    .navigation {
      a {
        margin: 0 0.5rem;
      }
    }
    .wrap {
      padding: 0 20px;
    }
  }

  @include below-desktop {
    padding: 0 1rem;

    .wrap {
      padding: 0 20px;
    }

    .navigation {
      a {
        min-width: 80px;
      }
    }

    .right-col {
      min-width: 100px;
    }

    .profile {
      margin-left: 0.5rem;
      .profile-image {
        width: 30px;
        height: 30px;
      }
    }

    .location {
      margin-right: 0.5rem;
      font-size: 13px;
    }
  }

  @include tablet-wide {
    &.mobile-menu-active {
      box-shadow: none;
    }
    .logo-block {
      a {
        font-family: MTSCompactBold;
        font-size: 17px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
      }
    }

    .desktop-navigation {
      display: none;
    }

    .mobile-navigation {
      display: block;
    }
  }
}

.mobile-navigation-bg {
  width: 100%;
  height: 100%;
  background: rgba(29, 32, 35, 0.40);
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  @media screen and (min-width: 900px){
    display: none;
  }
}

.menu-link-active {
  border-bottom: 2px solid #E30611 !important;
}
