@import "../colors.scss";
@import "../mixins.scss";

.full-site-footer {
  padding: 2rem 2rem 1rem 2rem;
  .footer-wrap {
    padding-left: 40px !important;
    padding-right: 40px !important;
    max-width: 1280px;
    margin: auto;
  }
  p {
    color: $gray-text;
    font-size: 14px;
    line-height: 30px;
    a {
      color: $light-blue-text;
    }
  }

  ol {
    padding-left: 1rem;
    margin-bottom: 0;
    li {
      color: $gray-text;
      margin-bottom: 0.5rem;
      a {
        color: $light-blue-text;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .btn {
    max-width: 270px;
    margin: auto;
  }
}
.footer-info {
  max-width: 58%;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.footer-info-title {
  margin-bottom: 8px;
  font-family: MTSCompactMedium;
  font-size: 20px;
  color: $dark-text;
}
.footer-info-text {
  @include prime-text-normal;
}
.footer-subtitle {
  font-family: MTSCompactMedium !important;
}
.footer-contact {
  margin-bottom: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.footer-contact-icon {
  background-repeat: no-repeat;
  background-size: contain;
}
.footer-contact-icon-phone {
  width: 18px;
  height: 18px;
  margin-right: 15px;
  background-image: url('#{$VUE_APP_BASE}/assets/icons/footer/footer-phone.svg');
}
.footer-contact-icon-mail {
  width: 20px;
  height: 16px;
  margin-right: 13px;
  background-image: url('#{$VUE_APP_BASE}/assets/icons/footer/footer-mail.svg');
}
.footer-contact-link {
  @include prime-text-small-uncolored;
  color: $link-blue-text;
}
.footer-contact-icon-tg {
  display: block;
  width: 32px;
  height: 32px;
  background-image: url('#{$VUE_APP_BASE}/assets/icons/footer/footer-telegram.svg');
}

@include tablet {
  .footer-info {
    max-width: unset;
  }
}

@include mobile {
  .full-site-footer {
    padding: 2rem 2rem 1rem 2rem;
    .footer-wrap {
      padding-left: 20px !important;
      padding-right: 20px !important;
    }
  }
}
