@import "colors.scss";
@import "mixins.scss";

.landing-page {
  max-width: 100%;
  overflow: hidden;
  padding: 0;

  .btn {
    color: $dark-text;
    width: 96%;
    max-width: 335px;
    background: white;
    transition: 0.3s;
    border-radius: 6px;
    &:hover {
      width: 100%;
    }
  }

  .btn-blue {
    font-family: MTSCompactMedium;
    color: white;
    width: 96%;
    font-size: 18px;
    max-width: 335px;
    transition: 0.3s;
    border-radius: 8px;
    background: rgb(53,115,217);
    background: linear-gradient(90deg, rgba(53,115,217,1) 0%, rgba(85,175,247,1) 100%);
    &:hover {
      width: 100%;
    }
  }

  a {
    &:hover,
    &:focus,
    &:active {
      box-shadow: none;
      outline: none;
    }
  }

  h3 {
    font-family: MTSWideMedium;
  }

  .block-header {
    h2 {
      font-family: MTSWideMedium;
      font-size: 32px;
      line-height: 36px;
      //margin-bottom: 1.5rem;
    }
    p {
      @include prime-text-normal-uncolored;
      max-width: 550px;
      line-height: 24px;
    }

    @include intermediate {
      h2 {
        //font-size: 28px;
        //margin-bottom: 1rem;
      }
      p {
        font-size: 16px;
      }
    }

    @include mobile {
      h2 {
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
      }

      p {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
    }
  }

  .block-wrap {
    position: relative;
    max-width: 1440px;
    margin: auto;
  }

  @keyframes mainScreenMapAnimation {
    0% {
      background-position: 100% 0;
      background-size: 20%;
    }
    70% {
      background-size: 50%;
    }
    100% {
      background-size: 90%;
      background-position: 100% 60%;
    }
  }

  /* ==================== Анимация блока data-presentation ==================== */

  @keyframes dataPresentation1 {
    20% {
      opacity: 1;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes dataPresentation2 {
    20% {
      opacity: 0;
    }

    40% {
      opacity: 1;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes dataPresentation3 {
    40% {
      opacity: 0;
    }

    60% {
      opacity: 1;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes dataPresentation4 {
    60% {
      opacity: 0;
    }

    80% {
      opacity: 1;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes dataPresentation5 {
    80% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }


  /* ======================================== */



  /* ==================== Анимация блока investment ==================== */

  @keyframes investment1 {
    33% {
      opacity: 1;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes investment2 {
    33% {
      opacity: 0;
    }

    66% {
      opacity: 1;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes investment3 {
    66% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  /* ======================================== */

  @keyframes territoryAnim {
    0% {
      transform: rotate(0deg);
    }

    50% {
      transform: rotate(180deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes borderAnim {
    0% {
      background-position: 0 80%;
    }
    100% {
      background-position: -350px 80%;
    }
  }

  /* ======================= animations digital ======================== */

  @keyframes digitalCitizen1 {
    25% {
      opacity: 1;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes digitalCitizen2 {
    25% {
      opacity: 0;
    }

    50% {
      opacity: 1;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes digitalCitizen3 {
    50% {
      opacity: 0;
    }

    75% {
      opacity: 1;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes digitalCitizen4 {
    75% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes digitalTourist1 {
    20% {
      opacity: 1;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes digitalTourist2 {
    20% {
      opacity: 0;
    }

    40% {
      opacity: 1;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes digitalTourist3 {
    40% {
      opacity: 0;
    }

    60% {
      opacity: 1;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes digitalTourist4 {
    60% {
      opacity: 0;
    }

    80% {
      opacity: 1;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes digitalTourist5 {
    80% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  /* =============================================== */

  .digital {
    position: relative;
    .digital-bg {
      position: fixed;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background:
              right / contain no-repeat url("#{$VUE_APP_BASE}/assets/images/landing/hexagons.svg"),
              right / cover no-repeat url("#{$VUE_APP_BASE}/assets/images/landing/mask.svg");
      z-index: -2;
    }
    .digital-wrap {
      margin: auto;
      padding: 6rem 2rem;
      max-width: 1440px;
    }
    .block-content {
      display: flex;
      flex-direction: column;
      .block-header {
        margin-top: 5.5rem;
      }
      .block-item {
        position: relative;
        width: auto;
        max-width: 600px;
        margin-bottom: 2rem;
        .block-item-content {
          position: relative;
          z-index: 1;
        }

        h3 {
          background: linear-gradient(74.08deg, #3573D9 37.91%, #55AFF7 97.54%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: 28px;
          margin-bottom: 2.5rem;
        }
        ul {
          padding: 0;
          li {
            position: relative;
            padding-left: 2rem;
            opacity: 0;
            font-size: 17px;
            line-height: 24px;
            list-style: none;
            margin-bottom: 1.5rem;
            .icon {
              position: absolute;
              left: 0;
              top: 3px;
              margin-right: 1.25rem;
              &:before {
                width: 16px;
                height: 16px;
              }
            }

            &:first-child,
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4),
            &:last-child {
              animation-duration: 4s;
              animation-timing-function: linear;
              animation-iteration-count: 1;
              animation-fill-mode: forwards;
            }
          }
        }
      }
    }

    &.animated {
      .block-content {
        .citizen-block-item {
          ul {
            li {
              &:first-child {
                animation-name: digitalCitizen1;
              }
              &:nth-child(2) {
                animation-name: digitalCitizen2;
              }
              &:nth-child(3) {
                animation-name: digitalCitizen3;
              }
              &:last-child {
                animation-name: digitalCitizen4;
              }
            }
          }
        }

        .tourist-block-item {
          ul {
            li {
              &:first-child {
                animation-name: digitalTourist1;
              }
              &:nth-child(2) {
                animation-name: digitalTourist2;
              }
              &:nth-child(3) {
                animation-name: digitalTourist3;
              }
              &:nth-child(4) {
                animation-name: digitalTourist4;
              }
              &:last-child {
                animation-name: digitalTourist5;
              }
            }
          }
        }
      }
    }

    @include below-desktop {
      .digital-wrap {
        padding: 3rem 1rem;
        .block-content {
          .block-header {
            margin-top: 0;
          }
        }
      }
    }

    @include mobile {
      .digital-bg {
        background: url("#{$VUE_APP_BASE}/assets/images/landing/mask.svg") no-repeat;
        background-position: center;
        background-size: cover;
      }
      .digital-wrap {
        padding: 2rem 1rem;
        .block-content {
          .block-item {
            margin-bottom: 3rem;
            &:last-child {
              margin-bottom: 2rem;
            }
            .block-item-content {
              h3 {
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
                margin-bottom: 1.5rem;
              }
              uL {
                margin-bottom: 0;
                li {
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 20px;
                  margin-bottom: 0.75rem;
                  &:last-child {
                    margin-bottom: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }



  /* ==================== Анимация блока data-presentation ==================== */

  @keyframes dashboards1 {
    33% {
      opacity: 1;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes dashboards2 {
    33% {
      opacity: 0;
    }

    66% {
      opacity: 1;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes dashboards3 {
    66% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  /* ======================================== */

  .dashboards {
    position: relative;
    background: $white-text;
    z-index: 1;
    padding: 4rem 2rem 5rem 2rem;
    .block-content {
      .mobile-version {
        display: none;
        margin-top: 2.5rem;
        .block-item {
          margin: 0;
        }
      }

      .desktop-version {
        margin-top: 3rem;
        display: flex;
        justify-content: space-between;
      }

      .block-item {
        opacity: 0;
        position: relative;
        max-width: 370px;
        line-height: 24px;
        font-size: 17px;
        padding: 2rem;
        .block-item-content {
          position: relative;
          z-index: 1;
        }

        h3 {
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: 28px;
          margin-bottom: 1rem;
          span {
            font-family: inherit;
            background: linear-gradient(74.08deg, #3573D9 37.91%, #55AFF7 97.54%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 28px;
          }
        }
        p {
          margin-bottom: 3rem;
          font-size: 14px;
          line-height: 20px;
          color: $dark-text;
        }

        &:first-child,
        &:nth-child(2),
        &:last-child {
          animation-duration: 3s;
          animation-timing-function: linear;
          animation-iteration-count: 1;
          animation-fill-mode: forwards;
        }
      }

      .img-block {
        width: 100%;
        height: 100%;
        max-height: 230px;
        min-height: 230px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
      }
      .img-events {
        background: bottom right/70% no-repeat url(/assets/images/landing/objects/events.png),
        top left/70% no-repeat url(/assets/images/landing/objects/events2.png);
      }
      .img-area {
        background: bottom right/70% no-repeat url(/assets/images/landing/objects/fok2.png),
        top left/70% no-repeat url(/assets/images/landing/objects/fok.png);
      }
      .img-monitoring {
        background-image: url("#{$VUE_APP_BASE}/assets/images/landing/monitoring.png");
      }
    }

    &.animated {
      .block-content {
        .block-item {
          &:first-child {
            animation-name: dashboards1;
          }
          &:nth-child(2) {
            animation-name: dashboards2;
          }
          &:last-child {
            animation-name: dashboards3;
          }
        }
      }
    }

    @include below-desktop {
      padding: 3rem 1rem;
      .block-content {
        .block-item {
          padding: 1rem;
        }
      }
    }

    @include tablet {
      .block-content {
        .desktop-version {
          flex-wrap: wrap;
          justify-content: center;
        }
        .block-item {
          margin-bottom: 2rem;
          max-width: 450px;
        }
      }
    }

    @include mobile {
      .block-content {
        .mobile-version {
          display: block;
          //margin-top: 2.5rem;
        }

        .desktop-version {
          display: none;
        }
        .block-item {
          padding: 0 0.25rem;
          margin: 1rem 0;
          h3 {
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 28px;
            span {
              font-size: 18px;
              font-style: normal;
              font-weight: 500;
              line-height: 28px;
            }
          }
          p {
            margin-bottom: 1rem;
          }
        }
      }
    }
  }

  .business {
    background: right / cover no-repeat url("#{$VUE_APP_BASE}/assets/images/landing/mask.png");
    .business-wrap {
      margin: auto;
      max-width: 1440px;
      padding: 5rem 2rem;
      position: relative;
    }
    .block-header {
      margin-bottom: 5rem;
      p {
        max-width: 100%;
      }
      .btn-blue {
        max-width: 280px;
        height: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover {
          max-width: 285px;
        }
      }
    }
    .block-header-demo {
      margin-top: 200px;
      margin-bottom: 200px;
      h3 {
        font-size: 24px;
        font-family: MTSWideMedium;
        margin-bottom: 1rem;
      }
      p {
        font-family: MTSWideRegular;
        font-size: 20px;
        margin-bottom: 34px;
      }
      .btn {
        font-size: 17px;
      }
    }
    .block-content {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      margin-bottom: 6.5rem;

      .business-items {
        display: flex;
        justify-content: space-between;
        width: 100%;
        min-height: 155px;
      }

      .block-item {
        opacity: 0;
        position: relative;
        display: flex;
        text-align: center;
        justify-content: center;
        border-radius: 16px;
        max-width: 235px;
        width: 100%;

        h3 {
          background: linear-gradient(74.08deg, #3573D9 37.91%, #55AFF7 97.54%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;

          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: 28px;
          text-align: left;
          margin-bottom: 1rem;
        }

        p {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          text-align: left;
        }

        &:first-child,
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4),
        &:last-child {
          animation-duration: 5s;
          animation-timing-function: linear;
          animation-iteration-count: 1;
          animation-fill-mode: forwards;
        }
      }
    }

    .carousel-3d-container {
      height: 460px !important;
      padding: 20px 0;
      .carousel-3d-slider {
        .carousel-3d-slide {
          display: flex;
          background: no-repeat;
          border: none;
          box-shadow: 0px 12px 20px 0px rgba(0, 0, 0, 0.14), 0px 4px 24px 0px rgba(0, 0, 0, 0.12);
          .business-slider-image {
            background-repeat: no-repeat;
            background-size: cover;
            background-position: top;
            width: 100%;
            height: 100%;
          }
        }
      }
      .carousel-3d-controls {
        .prev,
        .next {
          border-radius: 8px;
          overflow: hidden;
          transition: 0.3s;
          &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            backdrop-filter: blur(3px);
            filter: blur(3px);
          }
          &:after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: url("#{$VUE_APP_BASE}/assets/icons/slider-arrow.svg") no-repeat center;
          }
          span {
            display: none;
          }

          &:hover {

          }
        }

        .prev {
          left: 0;
        }

        .next {
          right: 0;
          transform: rotate(180deg);
        }
      }
    }

    &.animated {
      .block-content {
        .block-item {
          &:first-child {
            animation-name: dataPresentation1;
          }

          &:nth-child(2) {
            animation-name: dataPresentation2;
          }

          &:nth-child(3) {
            animation-name: dataPresentation3;
          }

          &:nth-child(4) {
            animation-name: dataPresentation4;
          }

          &:last-child {
            animation-name: dataPresentation5;
          }
        }
      }
    }

    @include below-desktop {
      .business-wrap {
        padding: 3rem 1rem;
        .block-header {
          margin-bottom: 3rem;
        }
        .block-header-demo {
          margin-bottom: 200px;
        }
      }
      .block-content {
        margin-bottom: 3.5rem;
        .business-items {
          flex-wrap: wrap;
          justify-content: center;
        }
        .block-item {
          margin: 0 1rem;
          margin-bottom: 2rem;
          &:first-child {
            max-width: 210px;
          }
          &:nth-child(2) {
            align-items: flex-start;
          }
        }
      }
    }

    @include intermediate {
      .block-content {
        .block-item {
          margin: 0 0.5rem;
        }
      }
    }

    @include tablet {
      background-position: top right;
      background-size: cover;
      .business-wrap {
        padding: 3rem 1rem;
        .block-header-demo {
          margin-top: 100px;
          margin-bottom: 100px;
        }
      }
      .block-content {
        padding-left: 0;
        .business-items {
          justify-content: space-around;
          .block-item {
            margin: 0;
            margin-bottom: 2rem;
            max-width: 43%;
            justify-content: flex-start;
          }
        }
      }
    }

    @include mobile {
      background: $white-text;
      .business-wrap {
        padding-bottom: 2rem;
      }

      .block-header {
        margin-bottom: 2.5rem;
      }

      .block-header-demo {
        margin-top: 2rem;
        h2 {
          text-align: center;
        }
      }

      .block-content {
        width: 100vw;
        margin: 0 -1rem;
        padding: 0 1rem;
        background: url("#{$VUE_APP_BASE}/assets/images/landing/eclipse.svg") no-repeat center;
        .business-items {
          display: block;
          .block-item {
            padding: 0;
            max-width: 200px;
            margin-bottom: 2rem;
            h3 {
              font-size: 18px;
              font-style: normal;
              font-weight: 500;
              line-height: 28px;
              span {
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 28px;
              }
            }
            h2 {
              br {
                display: none;
              }
            }
            p {
              br {
                display: none;
              }
            }
            &:nth-child(even) {
              margin-left: auto;
            }
            &:nth-child(3) {
              min-width: 240px
            }
          }
        }
      }

      .business-slider {
        .carousel-3d-container {
          margin: 0;
          height: auto !important;
          .carousel-3d-controls {
            top: calc(50% + 12px);
          }
        }
      }
    }
  }

  .full-site-footer {
    background: #F2F3F7;
    .btn {
      max-width: 270px;
    }
    .footer-wrap {
      .mb-4 {
        margin-bottom: 1.25rem !important;
      }

      p,
      li {
        font-family: MTSTextRegular;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }

      .btn-text {
        .buttonDH {
          height: auto;
          background: none;
          padding: 0;
          width: auto;
          .buttonContentDH {
            color: $light-blue-text;
          }
        }
      }
    }
    .docs {
      display: flex;
      flex-wrap: wrap;
      .icon {
        &:before {
          content: '';
          display: block;
          width: 44px;
          height: 44px;
          margin-bottom: 0.75rem;
        }
      }
      .icon-doc {
        &:before {
          background-image: url('#{$VUE_APP_BASE}/assets/icons/doc.svg');
          background-repeat: no-repeat;
          background-size: contain;
        }
      }
      .file {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        cursor: pointer;
        max-width: 115px;
        margin-right: 1rem;
        margin-bottom: 1rem;
        font-size: 12px;
        line-height: 16px;
        text-decoration: none;
        text-align: center;
        span {
          color: $gray-text;
        }
        &:hover {
          span {
            color: $light-blue-text;
          }
        }
      }
    }

    @include below-desktop {
      padding: 2rem 1rem;
    }

    @include mobile {
      ol {
        margin-bottom: 1rem;
      }

      .col-12 {
        &:last-child {
          margin-top: 1rem;
          margin-bottom: 0 !important;
        }
      }
    }
  }
}

/* ======================================== Slider block  ======================================== */

.ssr-carousel-back-button,
.ssr-carousel-next-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: inline-block;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}
.ssr-carousel-back-button {
  left: 2%;
}
.ssr-carousel-next-button {
  right: 2%;
}
.ssr-carousel-back-icon,
.ssr-carousel-next-icon {
  display: inline-block;
  width: 42px;
  height: 42px;
  background-color: rgba(0,0,0,0.5);
  border-radius: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.2s;
}
[aria-disabled] > .ssr-carousel-back-icon,
[aria-disabled] > .ssr-carousel-next-icon {
  opacity: 0.1;
  cursor: default;
}
:not([aria-disabled]) > .ssr-carousel-back-icon,
:not([aria-disabled]) > .ssr-carousel-next-icon {
  opacity: 0.5;
}
@media (hover: hover) {
  :not([aria-disabled]) > .ssr-carousel-back-icon:hover,
  :not([aria-disabled]) > .ssr-carousel-next-icon:hover {
    opacity: 0.85;
  }
}
:not([aria-disabled]) > .ssr-carousel-back-icon:active,
:not([aria-disabled]) > .ssr-carousel-next-icon:active {
  opacity: 1;
}
:not([aria-disabled]) > .ssr-carousel-back-icon.active,
:not([aria-disabled]) > .ssr-carousel-next-icon.active {
  opacity: 1;
}
.ssr-carousel-back-icon:before,
.ssr-carousel-next-icon:before {
  content: '';
  position: relative;
}
.ssr-carousel-back-icon:before {
  width: 0;
  height: 0;
  background: 0;
  border-style: solid;
  border-width: 9px 12px 9px 0;
  border-color: transparent #fff transparent transparent;
  left: -2px;
}
.ssr-carousel-next-icon:before {
  width: 0;
  height: 0;
  background: 0;
  border-style: solid;
  border-width: 9px 0 9px 12px;
  border-color: transparent transparent transparent #fff;
  left: 2px;
}

.ssr-carousel-dot-button {
  display: inline-block;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}
.ssr-carousel-dots {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}
.ssr-carousel-dot-icon {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 6px;
  background: #D9D9D9;
  margin-left: 4px;
  margin-right: 4px;
  transition: opacity 0.2s;
}
[aria-disabled] > .ssr-carousel-dot-icon {
  opacity: 1;
  background: rgba(0,0,0,0.7);
  cursor: default;
}
:not([aria-disabled]) > .ssr-carousel-dot-icon {
  opacity: 0.5;
}
@media (hover: hover) {
  :not([aria-disabled]) > .ssr-carousel-dot-icon:hover {
    opacity: 0.85;
  }
}
:not([aria-disabled]) > .ssr-carousel-dot-icon:active {
  opacity: 1;
}
:not([aria-disabled]) > .ssr-carousel-dot-icon.active {
  opacity: 1;
}

#ZVK_heading, #ZVK_success_screen-header, #ZVK_success_screen-text {
  color: $dark-text;
}
#ZVK_success_screen-header, #ZVK_success_screen-text {
  margin-right: 0;
}
#ZVK_company_list button {
  background: white !important;
  & div {
    color: $dark-text !important;
  }
}
#ZVK_form_submit-button,
#ZVK_form_ok-button {
  //background: $dark-text !important;
  border-radius: 8px;
  div,
  span {
    font-family: MTSSansMedium !important;
    font-size: 14px !important;
    color: white !important;
  }
}

.ssr-carousel-track {
  display: flex;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.ssr-carousel-track.dragging {
  pointer-events: none;
}
.ssr-carousel-slide {
  flex-shrink: 0;
}
.ssr-carousel-mask.disabled .ssr-carousel-slide[aria-hidden='true'] {
  display: none;
}

.ssr-carousel {
  touch-action: pan-y;
}
.ssr-carousel-slides {
  position: relative;
}
.ssr-peek-values {
  position: absolute;
}
.ssr-carousel-mask {
  position: relative;
}
.ssr-carousel-mask:not(.no-mask) {
  overflow: hidden;
}
.ssr-carousel-mask:not(.disabled):not(.not-draggable) {
  cursor: -webkit-grab;
  cursor: grab;
}
.ssr-carousel-mask:not(.disabled):not(.not-draggable).pressing {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}
.ssr-carousel-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
}

