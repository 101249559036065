@import "colors.scss";

@mixin prime-text-small {
  font-family: MTSCompactRegular;
  font-size: 14px;
  color: $dark-text;
}

@mixin prime-text-small-uncolored {
  font-family: MTSCompactRegular;
  font-size: 14px;
}

@mixin medium-text-small-uncolored {
  font-family: MTSCompactMedium;
  font-size: 14px;
}

@mixin prime-text-normal {
  font-family: MTSCompactRegular;
  font-size: 17px;
  color: $dark-text;
}

@mixin prime-text-normal-uncolored {
  font-family: MTSCompactRegular;
  font-size: 17px;
}

@mixin medium-text-normal-uncolored {
  font-family: MTSCompactMedium;
  font-size: 17px;
}

@mixin mobile {
  @media screen and (max-width: 640px) {
    @content;
  }
}

@mixin tablet {
  @media screen and (max-width: 800px) {
    @content;
  }
}

@mixin tablet-wide {
  @media screen and (max-width: 900px) {
    @content;
  }
}

@mixin intermediate {
  @media screen and (max-width: 1000px) {
    @content;
  }
}

@mixin below-desktop {
  @media screen and (max-width: 1279px) {
    @content;
  }
}